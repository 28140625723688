// State
export const state = () => ({
	paymentStats: {},
	playerChart: {},
	playerChartDate: {},
	playerDepositChart: {
		label: new Array(31).fill(''),
		newDepositAmount: new Array(31).fill(0),
		regularDepositAmount: new Array(31).fill(0),
		newWithdrawAmount: new Array(31).fill(0),
		regularWithdrawAmount: new Array(31).fill(0),
		turnoverDepositRatio: new Array(31).fill(0),
		turnover: new Array(31).fill(0),
		netCashflow: new Array(31).fill(0)
	},
	playerDepositChartDate: {},
	playerAccountAgeChart: {
		label: [1, 2, 3, 4, 5, 6, 7],
		playerCount: []
	},
	playerFinancial: {
		label: [],
		betWinLoss: [],
		depositAmount: [],
		depositCount: [],
		withdrawalAmount: [],
		withdrawCount: [],
		netCashflow: [],
		turnover: [],
		turnoverDepositRatio: []
	},

	// cancel token
	dashboardCancelToken: {}
})

// Actions
export const actions = {
	async paymentStats({ commit, state }, params) {
		commit('setDashboardCancelToken', { stats: this.$axios.CancelToken.source() })
		await this.$axios.get(`/statistics/dashboard/payment-stats`, { params, cancelToken: state.dashboardCancelToken.stats.token })
			.then((response) => {
				commit('setPaymentStats', response.data)
			}).catch(() => false)
	},
	async playerChart({ commit, state }, params) {
		commit('setDashboardCancelToken', { chart: this.$axios.CancelToken.source() })
		await this.$axios.get(`/statistics/dashboard/player-information-chart`, { params })
			.then((response) => {
				if (params.date) {
					commit('setPlayerChartDate', response.data)
				} else {
					commit('setPlayerChart', response.data)
				}
			}).catch(() => false)
	},
	async playerAccountAges({ commit, state }, params) {
		commit('setDashboardCancelToken', { accountAge: this.$axios.CancelToken.source() })
		await this.$axios.get(`/statistics/dashboard/account-age-chart`, { params, cancelToken: state.dashboardCancelToken.accountAge.token })
			.then((response) => {
				commit('setPlayerAccountAgeChart', response.data)
			}).catch(() => false)
	},
	async playerFinancialChart({ commit, state }, { id, params }) {
		commit('setDashboardCancelToken', { playerFinancialChart: this.$axios.CancelToken.source() })
		await this.$axios.get(`/statistics/players/player-finance-chart/${id}`, { params, cancelToken: state.dashboardCancelToken.playerFinancialChart.token })
			.then((response) => {
				commit('setPlayerFinancial', response.data)
			}).catch(() => false)
	},
	async playerChartBar({ commit, state }, params) {
		let cancelToken = null;
		// Daily report chart
		if (params.date) {
			if (state.dashboardCancelToken.dailyChart) {
				state.dashboardCancelToken.dailyChart.cancel('')
			}
			commit('setDashboardCancelToken', { dailyChart: this.$axios.CancelToken.source() })
			cancelToken = state.dashboardCancelToken.dailyChart.token
		} else if (params.type === 1) {
			if (state.dashboardCancelToken.dailyChartWithoutTurnover) {
				state.dashboardCancelToken.dailyChartWithoutTurnover.cancel('')
			}
			commit('setDashboardCancelToken', { dailyChartWithoutTurnover: this.$axios.CancelToken.source() })
			cancelToken = state.dashboardCancelToken.dailyChartWithoutTurnover.token
		} else if (params.type === 2) {
			if (state.dashboardCancelToken.dailyChartTurnover) {
				state.dashboardCancelToken.dailyChartTurnover.cancel('')
			}
			commit('setDashboardCancelToken', { dailyChartTurnover: this.$axios.CancelToken.source() })
			cancelToken = state.dashboardCancelToken.dailyChartTurnover.token
		}

		await this.$axios.get(`/statistics/dashboard/financial-information-chart`, {
			params,
			cancelToken
		})
			.then((response) => {
				if (params.date) {
					commit('setPlayerDepositChartDate', response.data)
				} else {
					commit('setPlayerDepositChart', response.data)
				}
			}).catch(() => false)
	},

}

// Mutations
export const mutations = {
	setPaymentStats(state, response) {
		state.paymentStats = response
	},
	setPlayerChart(state, response) {
		state.playerChart = response
	},
	setPlayerChartDate(state, response) {
		state.playerChartDate = response
	},
	setPlayerDepositChart(state, response) {
		const clone = state.playerDepositChart
		const labelLength = response?.label?.length
		if (response?.label) clone.label = response.label
		if (response.netCashflow) clone.netCashflow = response.netCashflow
		if (response.newDepositAmount) clone.newDepositAmount = response.newDepositAmount
		if (response.newWithdrawAmount) clone.newWithdrawAmount = response.newWithdrawAmount
		if (response.regularDepositAmount) clone.regularDepositAmount = response.regularDepositAmount
		if (response.regularWithdrawAmount) clone.regularWithdrawAmount = response.regularWithdrawAmount
		if (response.turnover) clone.turnover = response.turnover
		if (response.winLose) clone.winLose = response.winLose

		clone.netCashflow.length = labelLength
		clone.newDepositAmount.length = labelLength
		clone.newWithdrawAmount.length = labelLength
		clone.regularDepositAmount.length = labelLength
		clone.regularWithdrawAmount.length = labelLength
		clone.turnover.length = labelLength
		clone.winLose.length = labelLength

		// turnoverDepositRatio calculator logic
		// turnover / totalDepositAmount
		clone.label.forEach((el, index) => {
			let depositRatio = 0
			const totalDepositAmount = parseFloat(clone.regularDepositAmount[index]) + parseFloat(clone.newDepositAmount[index])
			if (parseFloat(clone.turnover[index] || 0) !== 0 && (totalDepositAmount) !== 0) {
				depositRatio = parseFloat(clone.turnover[index]) / totalDepositAmount
			}
			clone.turnoverDepositRatio[index] = depositRatio
		})
		clone.turnoverDepositRatio.length = labelLength
		state.playerDepositChart = clone
	},
	setPlayerDepositChartDate(state, response) {
		state.playerDepositChartDate = response
	},
	setPlayerAccountAgeChart(state, response) {
		state.playerAccountAgeChart = response
	},
	setPlayerFinancial(state, response) {
		state.playerFinancial = response
	},
	setDashboardCancelToken: (state, data) => {
		const oldData = { ...state.dashboardCancelToken }
		state.dashboardCancelToken = {
			...oldData, ...data
		}
	}
}